<template>
  <b-container class="page-container">
    <bread-crumbs
      :bread-crumbs="breadCrumbs"
      :currentpage="`Gegevens van ${form.firstname} ${form.lastname} bewerken`"
    />
    <b-form @submit.prevent="updateSub">
      <h1>Gegevens van {{ form.firstname }} {{ form.lastname }} bewerken</h1>

      <b-row>
        <b-col sm="12" lg="8">
          <b-card>
            <h4>Gegevens van de contactpersoon bewerken</h4>

            <b-row>
              <b-col cols="6" class="pb-3">
                <label>
                  Voornaam
                  <span class="text-danger">*</span>
                </label>
                <b-form-input v-model="form.firstname" required></b-form-input>
              </b-col>
              <b-col cols="6" class="pb-3">
                <label>
                  Achternaam
                  <span class="text-danger">*</span>
                </label>
                <b-form-input v-model="form.lastname" required></b-form-input>
              </b-col>
              <b-col cols="6" class="pb-3">
                <label>
                  E-mailadres
                  <span class="text-danger">*</span>
                </label>
                <b-form-input v-model="form.email" required></b-form-input>
              </b-col>
              <b-col cols="6">
                <label>
                  Gsm
                  <span class="text-danger">*</span>
                </label>
                <b-form-input
                  v-model="form.CONTACT_MobilePhone"
                  oninput="javascript: this.value = this.value.replace(/[A-Za-z]/gi, '');"
                  required
                ></b-form-input>
              </b-col>
              <b-col cols="6">
                <label>
                  Telefoonnummer
                  <span class="text-danger">*</span>
                </label>
                <b-form-input
                  v-model="form.tel"
                  oninput="javascript: this.value = this.value.replace(/[A-Za-z]/gi, '');"
                  required
                ></b-form-input>
              </b-col>
              <b-col cols="12"> </b-col>
            </b-row>
          </b-card>

          <b-card class="mt-5">
            <b-row>
              <b-col>
                <h2 class="pt-2">Bevestigen?</h2>
              </b-col>
              <b-col class="text-right">
                <b-button variant="primary" type="submit">
                  Wijzigingen opslaan
                  <font-awesome-icon
                    v-if="processing"
                    class="fa-spin"
                    far
                    :icon="['fad', 'spinner']"
                  />
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import { modifySub } from '@/services/AuthorizationService'
import {
  BForm,
  BRow,
  BCol,
  BCard,
  BContainer,
  BButton,
  BFormInput
} from 'bootstrap-vue'
export default {
  components: {
    BForm,
    BRow,
    BCol,
    BCard,
    BContainer,
    BButton,
    BFormInput
  },
  data() {
    return {
      form: {
        email: '',
        password: null,
        confirmPassword: null,
        tel: '',
        firstname: '',
        lastname: '',
        CONTACT_MobilePhone: ''
      },
      registering: false,
      formFilled: false,
      processing: false,
      subUser: {},
      breadCrumbs: [{ title: 'Mijn account', path: '/account' }]
    }
  },
  computed: {
    subUsers: function() {
      return this.$store.getters.subUsers
    },
    passwordConfirmState() {
      if (this.form.password != '' && this.form.password != null) {
        if (this.form.password == this.form.confirmPassword) {
          return true
        }
        return false
      }
      return null
    }
  },
  async created() {
    this.subUser = this.subUsers.find(obj => {
      return obj.UserName === this.$route.params.username
    })
    this.form = {
      email: this.subUser.Email,
      street: this.subUser.CUST_Street,
      city: this.subUser.CUST_City,
      houseNumber: this.subUser.CUST_HouseNumber,
      postcode: this.subUser.CUST_ZipCode,
      tel: this.subUser.Tel,
      firstname: this.subUser.FirstName,
      lastname: this.subUser.LastName,
      CONTACT_MobilePhone: this.subUser.MobilePhone
    }
  },
  methods: {
    updateSub: async function() {
      this.processing = true
      // if (Object.values(this.form).some(x => x !== null && x !== '')) {
      //   alert('not all fields are filled')
      // }
      const result = await modifySub({
        email: this.form.email,
        password: this.form.password,
        confirmPassword: this.form.confirmPassword,
        street: this.form.street,
        city: this.form.city,
        houseNumber: this.form.houseNumber,
        postcode: this.form.postcode,
        firstname: this.form.firstname,
        lastname: this.form.lastname,
        contactID: this.subUser.ContactID,
        userName: this.subUser.UserName,
        tel: this.form.tel,
        CONTACT_MobilePhone: this.form.CONTACT_MobilePhone
      })

      if (result !== null) {
        this.$router.push({
          name: 'account',
          query: { message: 'Gegevens succesvol aangepast' }
        })
      }
    }
  }
}
</script>

<style></style>
